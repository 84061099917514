import React, { useState, useEffect } from 'react'
import {
    Grid,
    FormControl,
    FormControlLabel,
    RadioGroup,
    Radio,
    FormLabel,
    Button,
} from '@material-ui/core'
import {
    Form,
    Col
} from 'react-bootstrap'

const rp = require('request-promise')

function SelectMeal(props) {

    const [meals, setMeals] = useState([])
    const [selectMeal, setSelectMeal] = useState(null)
    const [selectPayment, setSelectPayment] = useState(null)
    const [isOrderable, setIsOrderable] = useState(false)
    const [dateOfMeal, setDateOfMeal] = useState('2020-06-05')

    useEffect(() => {
        if(selectMeal && selectPayment) {
            setIsOrderable(true)
        }
    }, [selectMeal, selectPayment])

    useEffect(() => {
        if(meals.length == 0) {
            const options = {
                uri: 'https://adapter-lunch-yo66ixv5ua-de.a.run.app/meals/'+dateOfMeal,
                headers: {
                    'User-Agent': 'Request-Promise'
                },
                json: true
            }
            rp(options).then(response => {
                setMeals(response.meals)
            })
        }
    }, [])

    function handleMeal(event) {
        setSelectMeal(event.target.value)
    } 

    function handlePayment(event) {
        setSelectPayment(event.target.value)
    } 

    function commitOrder() {
        console.log(selectMeal)
        console.log(selectPayment)
        const options = {
            method: 'POST',
            uri: 'https://adapter-lunch-yo66ixv5ua-de.a.run.app/order',
            headers: {
                'User-Agent': 'Request-Promise'
            },
            body: {
                "user":props.user,
                "meal":selectMeal,
                "payment":selectPayment,
                "date_of_meal":dateOfMeal
            },
            json: true
        }
        rp(options).then(response => {
            setMeals(response.meals)
        })
        props.handleOrder()
    }

    return (
        <div>
            <Form>
                <FormControl component="fieldset">
                    <FormLabel component="legend">เลือกเมนู</FormLabel>
                    <RadioGroup aria-label="paymentMethod" name="paymentMethod" onChange={handleMeal}>
                        <Form.Row>
                            <Grid
                            container
                            direction="row"
                            justify="center"
                            alignItems="center"
                            >
                                {
                                    meals.map(meal => {
                                        return (
                                            <div key={meal.name}>
                                                <FormControlLabel value={meal.name} control={<Radio />} label={meal.name} />
                                                <img src={meal.image}/>
                                            </div>
                                    )})
                                }
                            </Grid>
                        </Form.Row>
                    </RadioGroup>
                </FormControl>
                <hr/>
                <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                >          
                    <Form.Row>
                        <FormControl component="fieldset">
                        <FormLabel component="legend">เลือกวิธีส่ง Slip</FormLabel>
                            <RadioGroup aria-label="paymentMethod" name="paymentMethod" onChange={handlePayment}>
                                <FormControlLabel value="line" control={<Radio />} label="ส่งผ่าน Line ของ Bonus" />
                                <FormControlLabel value="upload" control={<Radio />} label="Upload" />
                            </RadioGroup>
                        </FormControl>
                    </Form.Row>
                    <hr/>
                </Grid>
                <Button variant="contained" color="primary" onClick={commitOrder} disabled={!isOrderable}>
                        สั่งซื้อ
                </Button>
            </Form>
            
        </div>
    )
}

export default SelectMeal