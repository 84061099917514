import React from 'react'
import {
    Navbar,
} from 'react-bootstrap'
 
const AdapterNavbar = props => {

    const navbarStyle = {
        backgroundColor: "black",
        borderStyle: "none none solid none",
        borderColor: "white",
    }

    return (
        <Navbar variant="dark" style={navbarStyle}>
            <Navbar.Brand href="#"><img src="http://adapterdigital.com/images/header/logo_s.png" />{' '}<b>Lunch Service</b></Navbar.Brand>  
        </Navbar>
    )
}

export default AdapterNavbar