const config = {
    apiKey: "AIzaSyCLKiSaioeyW_Z25PEkmC7YcHJkx7lSaQg",
    authDomain: "adapter-digital-lunch-service.firebaseapp.com",
    databaseURL: "https://adapter-digital-lunch-service.firebaseio.com",
    projectId: "adapter-digital-lunch-service",
    storageBucket: "adapter-digital-lunch-service.appspot.com",
    messagingSenderId: "856516899727",
    appId: "1:856516899727:web:2df5b6f3ba890f709291fa",
    measurementId: "G-CM1NSLWLX5"
}

export default config