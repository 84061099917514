import React, { 
  useState,
  useEffect
} from 'react'
import './App.css'

import {
  Container,
} from '@material-ui/core'

import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth'
import firebase from 'firebase'
import config from './Config'
import 'bootstrap/dist/css/bootstrap.min.css'

import SelectMeal from './components/SelectMeal'
import AdapterNavbar from './components/AdapterNavbar'

function App() {

  const [isLogin, setIsLogin] = useState(false)
  const [isOrder, setIsOrder] = useState(false)
  const [user, setUser] = useState(null)

  if (!firebase.apps.length) {
    firebase.initializeApp(config)
  }

  const uiConfig = {
    signInFlow: 'redirect',
    signInSuccessUrl: '/',
    signInOptions: [
      firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    ]
  }

  const login = <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()}/>
  //const selectMeal = <SelectMeal user={user} handleOrder={handleOrder}/>

  function handleOrder() {
    setIsOrder(true)
  }

  useEffect(() => {
    firebase.auth().onAuthStateChanged(function(user) {
      if(user) {
        console.log(user)
        setIsLogin(true)
        setUser(user)
      }
    })
  }, [])

  const containerStyle = {
    color: "white",
    padding: "10px",
    fontFamily: "Arial"
  };

  return (
    <div className="App">
      <AdapterNavbar/>
      <Container style={containerStyle}>
        {
          !isLogin ? login : (!isOrder ? <SelectMeal user={user} handleOrder={handleOrder}/> : <p>ขอขอบคุณที่ใช้บริการ</p>)
        }
      </Container>
    </div>
  )
}

export default App
